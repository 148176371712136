// Import libraries and polyfills
import 'classlist-polyfill';
import domready from 'domready';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
// import { default as cookie } from './modules/cookie';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';
import { default as externalLinks } from './modules/external-links';
import { default as geolocation } from './modules/geolocation';
import { default as closestOffices } from './modules/closest-offices';
import { default as buttonEvents } from './modules/button-events';
// import progressIndicator from './modules/progress-indicator';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as gtmEventHandler } from './modules/gtm-event';

// Import enhancers
import { enhancer as accordionPanel } from "./modules/accordion-panel";
import { enhancer as calculator } from './modules/calculator';
import { enhancer as collapsible } from './modules/collapsible';
import { enhancer as confetti } from './modules/confetti';
import { enhancer as extendedNavigation } from './modules/extended-navigation';
import { enhancer as formEnhancer } from './modules/form-enhancer';
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as hashToggler } from './modules/hash-toggler';
// @todo check hero script
import { enhancer as hero } from './modules/hero';
import { enhancer as location } from './modules/location';
import { enhancer as menuToggler } from './modules/menu-toggler';
import { enhancer as menuEnhancer } from './modules/menu-enhancer';
import { enhancer as newsletter } from './modules/newsletter';
import { enhancer as notice } from './modules/notice';
import { enhancer as office } from './modules/office';
import { enhancer as refinanceIntro } from './modules/refinance-intro';
import { enhancer as relatedPosts } from './modules/related-posts';
import { enhancer as searchFocus } from './modules/search-focus';
import { enhancer as stepsPanel } from "./modules/steps-panel";
import { enhancer as suggestedPosts } from './modules/suggested-posts';
import { enhancer as shareButtons } from './modules/share-buttons';
import { enhancer as textCollapser } from './modules/text-collapser';
import { enhancer as theme } from './modules/theme';
import { enhancer as reviews } from './modules/reviews';
import { enhancer as unload } from './modules/unload';
import { enhancer as quiz } from './modules/quiz';
// import { enhancer as upcomingSeminar } from './modules/upcoming-seminar';
import { enhancer as videoEmbed } from './modules/video-embed';
// import { enhancer as videoPlaylist } from './modules/video-playlist';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change
  buttonEvents(); // track buttons
  // cookie(); // Show cookie notice
  geolocation(); // Get geo location
  closestOffices(); // get closest office by geo
  externalLinks(); //
  //  progressIndicator(); // Show progess indicator for reading

  /**
   * Simple requestAnimationFrame
   * @see http://elektronotdienst-nuernberg.de/bugs/requestAnimationFrame.html
   */
  // eslint-disable-next-line func-names
  window.requestAnimationFrame = window.requestAnimationFrame || function (c) {
    return setTimeout(() => {
      c(+new Date());
    }, 30);
  };
};

function main() {
  executeOnReady();

  handle({
    classToggler,
    gtmEventHandler,
  });

  enhance({
    accordionPanel,
    calculator,
    collapsible,
    confetti,
    extendedNavigation,
    formEnhancer,
    gtmEventEnhancer,
    hashToggler,
    hero,
    location,
    menuToggler,
    menuEnhancer,
    newsletter,
    office,
    refinanceIntro,
    relatedPosts,
    searchFocus,
    shareButtons,
    stepsPanel,
    suggestedPosts,
    textCollapser,
    theme,
    reviews,
    unload,
    videoEmbed,
    notice,
    quiz,
  });
}

domready(() => {
  main();
});
